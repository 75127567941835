import { BlockButton, BlockLink, INavItem, useAppState } from '@gk-lab/kirbyreact'

type UsePageNavigation = {
  main: (BlockButton | BlockLink)[] | undefined
}

export default function usePageNavigation(): UsePageNavigation {
  const { navitems } = useAppState()
  return {
    main: navitems
  }
}

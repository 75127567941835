import React from 'react'
import { CircularProgress, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/system'

type Props = {
  sx?: SxProps<Theme>
}

export default function Loading(props: Props): React.JSX.Element {
  const { sx } = props
  return (
    <Stack
      flexDirection={'column'}
      sx={{ height: '80vh', width: '100%', justifyContent: 'center', alignItems: 'center', ...sx }}
    >
      <CircularProgress />
    </Stack>
  )
}

import { TypographyProps } from '@mui/material/Typography'

export enum CustomScope {
  MAIN = 'main',
  FOOTER = 'footer',
  HEADER = 'header',
  STAGE = 'stage',
  NAVIGATION = 'navigation'
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
}

export function getTypoVariant(scope: string | undefined): TypographyProps['variant'] | undefined {
  let result
  if (scope) {
    enumKeys(CustomScope).forEach((key) => {
      if (CustomScope[key] === scope) {
        result = CustomScope[key]
      }
    })
  }
  return result ?? undefined
}

import React, { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

export default function ScrollToTopOnPage(): null {
  const navigationType = useNavigationType()
  const { pathname } = useLocation()

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [navigationType, pathname])

  return null
}

import React from 'react'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

export function PageNotFound(): React.JSX.Element {
  const { pageUrl } = useParams()
  return (
    <Box>
      <Typography>Page not found: {pageUrl}</Typography>
    </Box>
  )
}

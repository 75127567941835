import React from 'react'
// import "./App.css";
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageRoot } from './pages/PageRoot'
import { IKirbySection, useAsset, useKirbyData } from '@gk-lab/kirbyreact'
import { CONTENTPATH } from './config'
import { PageNotFound } from './pages/PageNotFound'
import CustomAppBar from './components/CustomAppBar/CustomAppBar'
import usePageNavigation from './hooks/usePageNavigation'
import AppFooter from './components/AppFooter/AppFooter'
import ScrollToTopOnPage from './components/ScrollToTopOnPage/ScrollToTopOnPage'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Container from '@mui/material/Container'
import { Fab } from '@mui/material'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import Loading from './components/Loading/Loading'

function renderRoutes(sections?: IKirbySection[]): React.JSX.Element[] {
  let routes: React.JSX.Element[] = []
  if (sections) {
    routes = sections?.map((section) => {
      return <Route key={section.id} path={`/:pageUrl`} element={<PageRoot />} />
    })
  }
  routes.push(
    <Route
      key={'tohome'}
      path={`/`}
      element={<Navigate to={sections && sections[0] ? `/${sections[0].url}` : '/home'} />}
    />
  )
  routes.push(<Route key={'notfound'} path={'*'} element={<PageNotFound />} />)
  return routes
}

function App() {
  const { data } = useKirbyData(CONTENTPATH)

  const profileImage = useAsset('profile')
  const navigation = usePageNavigation()

  return (
    <div className="App">
      <header>
        <CustomAppBar
          profile={
            profileImage ? { url: profileImage?.image.xs.url, alt: profileImage?.alt } : undefined
          }
          items={navigation.main}
          id={'back-to-top-anchor'}
        />
      </header>
      <ScrollToTopOnPage />
      {/*<Jsonviewer data={data} />*/}
      {data && <Routes>{renderRoutes(data.sections)}</Routes>}
      {!data && <Loading />}
      <Container
        maxWidth={'md'}
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          pointerEvents: 'none'
        }}
      >
        <ScrollToTop
          boxSx={{
            bottom: '1rem',
            right: '4rem',
            position: 'absolute',
            pointerEvents: 'auto',
            width: 0,
            zIndex: 1000
          }}
        >
          <Fab color={'primary'} size={'medium'} aria-label="scroll back to top">
            <KeyboardArrowUp />
          </Fab>
        </ScrollToTop>
      </Container>
      <AppFooter />
    </div>
  )
}

export default App

import { createTheme, darken, lighten } from '@mui/material'
import { CustomScope } from './types/Scope'

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary']
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    main: true
    [CustomScope.FOOTER]: true
    [CustomScope.HEADER]: true
    [CustomScope.NAVIGATION]: true
    [CustomScope.STAGE]: true
  }
}
declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    transparent: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [CustomScope.FOOTER]: true
    [CustomScope.HEADER]: true
    [CustomScope.NAVIGATION]: true
    [CustomScope.STAGE]: true
  }
}

const font = 'Montserrat'

const fontHeading = 'Amatic SC'
const fontFamily = [font, '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')

export const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})

export const Colors = {
  backgroundLight: '#f8f8f8',
  backgroundDark: '#f3f3f3',
  darkGrey: '#727171',
  mediumGrey: '#afafaf',
  light: '#fdfdfd',
  primary: '#0e0e0e',
  accent: '#d33f49',
  accentLight: 'rgba(255,255,255,.6)',
  accentDark: '#9e3037',
  secondary: '#766973',
  bright: '#efefef',
  dark: '#3e3e3e',
  white: '#fff'
}

export const footerBackground = '#2b2b2b'

export const white = '#fff'
export const black = '#000'
export const dark = '#333'

export const primary = '#d33f49'
export const primaryDark = darken(primary, 0.5)
export const primaryLight = lighten(primary, 0.5)

export const secondary = '#565655'
export const secondaryDark = darken(secondary, 0.5)
export const secondaryLight = lighten(secondary, 0.5)

export const accent = '#9d6010'

export const lightGrey = '#F4F4F4'

export const defaultPadding = {
  xs: '1rem',
  sm: '4rem',
  md: '4rem',
  lg: '5rem',
  xl: '6rem'
}

export const theme = createTheme({
  breakpoints: baseTheme.breakpoints,
  spacing: 4,
  palette: {
    common: {
      black: '#000',
      white: white
    },
    primary: {
      main: primary,
      dark: primaryDark,
      light: primaryLight,
      contrastText: white
    },
    secondary: {
      main: secondary,
      dark: secondaryDark,
      light: secondaryLight,
      contrastText: '#ff0000'
    },
    accent: {
      main: accent,
      dark: darken(accent, 0.5),
      light: lighten(accent, 0.5),
      contrastText: white
    },
    error: {
      main: primary
    },
    warning: {
      main: '#FBC91D', // color-status-warning
      dark: '#AD1035', // color-secundary-1
      light: 'rgba(251,201,29,0.15)' // color-status-warning 15 %
    },
    info: {
      main: primary, // color-status-warning-high_prio
      light: 'rgba(215,15,55,0.07)' // color-status-warning-high_prio 7 %
    },
    success: {
      main: '#3C992E', // color-status-success
      light: 'rgba(10,153,46,0.1)' // color-status-success 10 %
    }
  },
  typography: {
    fontFamily: fontFamily
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h3',
          body1: 'p',
          body2: 'p'
        }
      },
      styleOverrides: {
        root: {
          color: secondaryDark,
          fontSize: '1.25rem',
          lineHeight: 1.4,
          fontFamily: fontHeading,
          textAlign: 'left',
          alignSelf: 'flex-start',
          width: '100%',
          '&.left': {
            textAlign: 'left'
          },
          '&.center': {
            textAlign: 'center',
            alignSelf: 'center'
          },
          '&.right': {
            textAlign: 'right',
            alignSelf: 'flex-end'
          },
          '&.primary': {
            color: primary
          },
          '&.secondary': {
            color: secondary
          },
          '&.accent': {
            color: accent
          },
          '& p, a': {
            marginBlockStart: '0 !important',
            marginBlockEnd: '1rem !important',
            marginInlineStart: '0 !important',
            marginInlineEnd: '0 !important',
            textAlign: 'inherit',
            fontFamily: fontFamily
          },
          p: {
            minHeight: '1rem'
          },
          '& a': {
            color: primary
            // whiteSpace: "nowrap",
          },
          '& a:hover': {
            color: primaryDark
          },
          '& h2,h3,h4,h5,h6': {
            fontFamily: fontHeading,
            textTransform: 'uppercase',
            textAlign: 'inherit',
            lineHeight: 1.1,
            marginBottom: '1rem'
          },
          '& h1': {},
          h3: {
            fontSize: '2.5rem',
            lineHeight: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginTop: '1rem',
            marginBottom: '1rem'
          },
          h4: {
            fontSize: '2.5rem',
            lineHeight: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginTop: '1rem',
            marginBottom: '1rem'
          },
          h5: {
            fontFamily: fontFamily,
            fontSize: '1.25rem',
            lineHeight: 1.25,
            marginTop: '1rem',
            marginBottom: '1rem',
            textTransform: 'none',
            color: secondary
          },
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
            '& h2,h3,h4,h5,h6': {
              lineHeight: 1.2,
              marginBottom: '1.5rem'
            },
            '& h2': {
              fontSize: '2rem'
            },
            '& h3': {
              fontSize: '3rem',
              marginBottom: '0'
            }
          },
          [baseTheme.breakpoints.up('lg')]: {
            '& h3': {
              fontSize: '3rem',
              marginTop: '3rem'
            }
          }
        },
        body1: {
          fontFamily: fontFamily,
          paddingBottom: '1rem',
          display: 'block',
          width: '100%',
          // Inline Styles
          '& p, h2, h3, h4, h5, h6': {},
          '& h2, h3, h4': {
            fontFamily: fontHeading,
            paddingLeft: '0',
            paddingRight: '0',
            marginBottom: '1rem',
            color: primary,
            fontWeight: 'bold'
          },
          '& p, h5, h6': {
            paddingLeft: '0',
            paddingRight: '0'
          },
          '& h4': {
            fontSize: '2.5rem',
            lineHeight: 1,
            fontWeight: 'bold',
            textTransform: 'uppercase'
          },
          '&.center': {
            alignSelf: 'center'
          },
          '&.footer': {
            fontSize: '1rem',
            marginBottom: '0',
            paddingBottom: '0',
            '> p': {
              marginBottom: '0',
              paddingBottom: '0',
              marginBlockStart: '0 !important',
              marginBlockEnd: '0 !important',
              marginInlineStart: '0 !important',
              marginInlineEnd: '0 !important'
            }
          },
          [baseTheme.breakpoints.up('sm')]: {
            lineHeight: 1.5
          }
        },
        body2: {
          fontFamily: fontFamily,
          paddingBottom: 0,
          '& p, a': {
            // marginBlockEnd: "0",
          },
          '&.copyright': {
            fontSize: '1rem'
          }
        },
        h1: {
          fontSize: '1.75rem',
          lineHeight: 1.2,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          position: 'relative',
          maxWidth: '100%',
          marginTop: '-0.5rem',
          letterSpacing: '0.1',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '2.5rem',
            letterSpacing: '.3rem'
          },
          [baseTheme.breakpoints.up('md')]: {
            marginTop: '-1rem',
            fontSize: '3rem'
          }
        },
        h2: {
          fontFamily: fontHeading,
          fontSize: '2.25rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginTop: '1rem',
          marginBottom: '1rem',
          lineHeight: '0.95',
          position: 'relative',
          [baseTheme.breakpoints.up('xl')]: {
            fontSize: '5rem'
          },
          '&.left, &.right': {
            '&::before': {
              position: 'absolute',
              width: '129px',
              height: '16px'
            }
          },
          '&.left': {
            marginLeft: 0,
            '&::before': {
              content: `url(svg/arrowtoright.svg)`,
              left: '-139px',
              top: '-6px'
            }
          },
          '&.right': {
            marginRight: 0,
            '&::before': {
              content: `url(svg/arrowtoleft.svg)`,
              right: '-139px',
              top: '-4px'
            }
          },
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '3rem',
            '&.left': {
              '&::before': {
                top: '-10px'
              }
            },
            '&.right': {
              '&::before': {
                top: '-12px'
              }
            }
          },
          [baseTheme.breakpoints.up('md')]: {
            marginTop: '2rem',
            fontSize: '4rem',
            '&.left': {
              '&::before': {
                top: '-16px'
              }
            },
            '&.right': {
              '&::before': {
                top: '-18px'
              }
            }
          }
        },
        h3: {
          fontFamily: fontHeading,
          fontSize: '2rem',
          lineHeight: 1.15,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginTop: '1rem',
          marginBottom: '1rem',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '2.5rem'
          },
          [baseTheme.breakpoints.up('md')]: {
            // marginTop: "3rem",
            fontSize: '3rem'
          },
          [baseTheme.breakpoints.up('xl')]: {
            // marginTop: "5rem",
          },
          '&.primary': {},
          '&.center': {
            alignSelf: 'center'
          }
        },
        h4: {
          fontFamily: fontHeading,
          fontSize: '2rem',
          fontWeight: 'bold',

          // marginTop: "2rem",
          // marginBottom: "1rem",
          textTransform: 'uppercase',
          [baseTheme.breakpoints.up('sm')]: {
            fontSize: '2.5rem'
          },
          [baseTheme.breakpoints.up('xl')]: {
            fontSize: '3rem'
          }
        },
        h5: {
          fontSize: '3rem',
          fontWeight: 'bold',
          color: accent,
          margin: '0',
          lineHeight: '1.8',
          marginBottom: '0',
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '4rem'
          }
        },
        h6: {
          fontSize: '1rem',
          transform: 'none',
          fontWeight: 'lighter',
          marginBottom: '0.25rem',
          marginTop: '0.25rem',
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '1.25rem'
          }
        },
        subtitle1: {
          fontSize: '2.0rem',
          lineHeight: '1',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginTop: '0',
          marginBottom: '1rem',
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '3rem'
          }
        },
        subtitle2: {
          color: secondary,
          fontSize: '2.0rem',
          lineHeight: '1',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginTop: '0',
          marginBottom: '1rem',
          [baseTheme.breakpoints.up('lg')]: {
            fontSize: '3rem'
          }
        }
      },
      variants: [
        {
          props: { variant: CustomScope.FOOTER },
          style: {
            color: secondary,
            paddingBottom: '0',
            textAlign: 'center',
            '& a, p': {
              color: secondary,
              textAlign: 'center',
              marginBlockEnd: '0 !important',
              textDecoration: 'none'
            },
            '& a:hover': {
              // fontWeight: "bold",
              color: primary
            },
            [baseTheme.breakpoints.up('lg')]: {}
          }
        }
      ]
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.navigation': {
            p: {
              fontFamily: `${fontHeading} !important`,
              padding: '0 1rem'
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          fontWeight: 'bold'
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            // color: `${primary} !important`,
          }
        }
        /*{
          props: { variant: "contained" },
          style: {
            color: secondary,
            alignSelf: "center",
            textAlign: "center",
            lineHeight: 1.1,
            padding: "1rem",
            backgroundColor: primary,
            "&.active": {
              color: secondary,
              backgroundColor: primary,
            },
            "&:hover,&:focus": {
              color: secondary,
              backgroundColor: primaryDark,
            },
          },
        },*/
      ]
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          // backgroundColor: accent,
        },
        paperFullScreen: {
          backgroundColor: accent
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          '.imagelist': {
            '.container-elements': {
              marginTop: '-1px',
              marginLeft: '-1px'
            },
            '.image': {
              outline: '1px solid #707070',
              marginTop: '1px',
              marginLeft: '1px'
            }
          },
          '.infoimage': {
            'h2,h3,h4': {
              position: 'relative',
              marginTop: '1rem'
              /*
              "&::before": {
                position: "absolute",
                width: "129px",
                height: "16px",
              },
              "&.left": {
                marginLeft: "4rem",
                "&::before": {
                  left: "-139px",
                  top: "-4px",
                },
              },
              "&.right": {
                marginRight: "4rem",
                "&::before": {
                  right: "-139px",
                  top: "-6px",
                },
              },
              "&.primary": {
                "&.left": {
                  "&::before": {
                    content: `url(svg/arrowtoright.svg)`,
                  },
                },
                "&.right": {
                  "&::before": {
                    content: `url(svg/arrowtoleft.svg)`,
                  },
                },
              },
              "&.secondary": {
                "&.left": {
                  "&::before": {
                    content: `url(svg/arrowtoright-white.svg)`,
                  },
                },
                "&.right": {
                  "&::before": {
                    content: `url(svg/arrowtoleft-white.svg)`,
                  },
                },
              }
              */
            }
          },
          '.footer-meta-container': {
            backgroundcolor: secondary,
            padding: '1rem',
            flexDirection: 'column',
            justifyContent: 'column',
            [baseTheme.breakpoints.up('sm')]: {
              padding: '1rem 8rem 2rem 8rem',
              flexDirection: 'row',
              justifyContent: 'space-between'
            },
            [baseTheme.breakpoints.up('lg')]: {
              padding: '1rem 10rem 2rem 10rem'
            },
            [baseTheme.breakpoints.up('xl')]: {
              padding: '1rem 12rem 2rem 12rem'
            }
          },
          '.section-stack': {
            '>div.headline,>div.css-wrapper>div.headline,>p.copy,>div.css-wrapper>p.copy': {
              padding: '0 1rem',
              [baseTheme.breakpoints.up('md')]: {
                padding: '0 4rem'
              },
              [baseTheme.breakpoints.up('lg')]: {
                padding: '0 5rem'
              },
              [baseTheme.breakpoints.up('xl')]: {
                padding: '0 6rem'
              }
            }
          },
          '.css-wrapper': {
            '&.left': {
              justifyContent: 'flex-start'
            },
            '&.center': {
              justifyContent: 'center'
            },
            '&.right': {
              justifyContent: 'flex-end'
            }
          }
        },
        maxWidthXl: {
          paddingLeft: '0',
          paddingRight: '0'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          'label > span': {
            fontSize: '1rem'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '4rem',
          backgroundcolor: secondary,
          color: dark,
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        }
      },
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            background: 'transparent'
          }
        }
      ]
    },
    MuiLink: {
      defaultProps: {
        fontFamily: fontFamily
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          '&.primary': {
            textDecorationColor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary
            }
          },
          '&.secondary, &.meta-navigation-link': {
            color: secondary,
            textDecorationColor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary
            }
          },
          '&.footer-meta-navigation': {
            fontSize: '1rem',
            '&:not(:last-child):after': {
              content: '"|"',
              margin: '0 0 0 0.5rem',
              textDecoration: 'none'
            },
            '&:hover,&:focus': {
              '&::after': {
                color: secondary
              }
            }
          },
          '&.meta-navigation-link': {
            color: secondary,
            textDecoration: 'none',
            '&:not(:last-child):after': {
              content: '"|"',
              margin: '0 0.5rem',
              textDecoration: 'none'
            },
            '&:hover,&:focus': {
              '&::after': {
                color: secondary
              }
            }
          },
          '&.accent': {
            color: secondary,
            textDecorationcolor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary
            }
          },
          '&.footer.secondary': {
            color: secondary,
            textDecorationcolor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary
            }
          },
          '&.main-navigation': {
            color: secondary,
            textTransform: 'uppercase',
            textDecoration: 'none',
            fontSize: '3rem',
            fontWeight: 'bold',
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
            [baseTheme.breakpoints.up('sm')]: {
              fontSize: '6rem'
            },
            [baseTheme.breakpoints.up('md')]: {
              fontSize: '6rem'
            },
            '&:hover,&:focus': {
              color: primary,
              textDecoration: 'none'
            },
            '&.active': {
              color: primary,
              textDecoration: 'none'
            }
          },
          '&.center': {
            margin: '0 auto'
          }
        }
      },
      variants: [
        {
          props: { variant: CustomScope.FOOTER },
          style: {
            color: secondary,
            textDecorationcolor: secondary,
            textAlign: 'center',
            marginBlockEnd: '0 !important',
            ':hover': {
              // fontWeight: "bold",
              color: primary
              // textDecorationcolor: secondary,
            },
            [baseTheme.breakpoints.up('lg')]: {
              // fontSize: "1rem",
            }
          }
        },
        {
          props: { variant: CustomScope.NAVIGATION },
          style: {
            color: secondary,
            textDecoration: 'none',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: '1.25rem',
            ':hover': {
              color: primary
            },
            [baseTheme.breakpoints.up('sm')]: {
              fontSize: '2rem'
            }
          }
        }
      ]
    },
    MuiDivider: {
      styleOverrides: {
        root: {},
        vertical: {
          borderColor: 'rgba(255,255,255,0.3)'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          //borderRadius: 0,
          //borderBottomRightRadius: "8px",
          //borderBottomLeftRadius: "8px",
          boxShadow: 'none'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1rem 1rem',
          textAlign: 'center',
          '.card-heading': {
            // textAlign: "center",
            color: secondaryDark,
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            lineHeight: 1,
            textTransform: 'upperCase',
            padding: 0,
            maxHeight: '3.5rem',
            textAlign: 'left',
            [baseTheme.breakpoints.up('md')]: {
              fontSize: '1.75rem'
            }
          },
          '.card-subtitle': {
            // textAlign: "center",
            fontSize: '1rem',
            marginBottom: '0.25rem',
            lineHeight: 1,
            padding: 0,
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#707070'
          },
          '.card-copy': {
            // textAlign: "center",
            fontSize: '1.25rem',
            margin: '0.5rem 0 0.25rem 0',
            padding: 0,
            '> p': {
              margin: 0
            }
          },
          [baseTheme.breakpoints.up('md')]: {
            '.card-heading': {
              // textAlign: "left"
            },
            '.card-subtitle': {
              // textAlign: "left"
            },
            '.card-copy': {
              // textAlign: "left"
            }
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: '0rem 1rem 2rem 1rem'
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.bullet-list': {
            '.title': {
              fontSize: '1.25rem',
              fontWeight: 'bold',
              color: secondary,
              textDecoration: 'underline',
              [baseTheme.breakpoints.up('md')]: {
                fontSize: '1.5rem'
              }
            },
            '> ul': {
              paddingLeft: '1.5rem',
              marginTop: '0',
              '> li': {
                paddingTop: 0,
                paddingBottom: 0,
                '&::before': {
                  content: '"・"',
                  marginLeft: '-1.25rem'
                }
              }
            }
          }
        }
      }
    }
  }
})

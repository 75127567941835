import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AppProvider } from '@gk-lab/kirbyreact'
import { CONTENTPATH } from './config'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './Theme'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider contentPath={CONTENTPATH}>
        <BrowserRouter>
          <Routes>
            <Route key={'app'} path={'*'} element={<App />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
)

import { IKirbyBlock, IKirbyImage } from '@gk-lab/kirbyreact'

export enum CustomBlockType {
  CUSTOM_COMPONENT = 'custom_component',
  MASONARY = 'masonary',
  IMAGELIST = 'imagelist'
}

export interface BlockCustom extends IKirbyBlock {
  type: CustomBlockType.CUSTOM_COMPONENT
  text: string
  variant?: string
}
export interface BlockMasonary extends IKirbyBlock {
  type: CustomBlockType.MASONARY
  images: IKirbyImage[]
}
export interface BlockImageList extends IKirbyBlock {
  type: CustomBlockType.IMAGELIST
  images: IKirbyImage[]
}

export type CustomBlock = BlockCustom | BlockImageList | BlockMasonary

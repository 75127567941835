import React from 'react'
import { Divider, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { Block, IKirbyBlock, INavItem, useAppState } from '@gk-lab/kirbyreact'
import { getBlockElement } from '../../utils/sectionUtils'
import { CustomBlock } from '../../types/Block'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

type Props = {
  sx?: SxProps<Theme>
}

export default function AppFooter(props: Props): React.JSX.Element {
  const { sx } = props
  const { footer } = useAppState()

  console.log('default - AppFooter: ', footer?.navitems)

  return (
    <footer>
      <Stack flexDirection={'column'} sx={{ my: 4, px: 2, ...sx }} gap={4}>
        <Divider />
        <Stack flexDirection={'column'} gap={2}>
          {footer?.blocks?.map((b: Block | CustomBlock, idx: number) =>
            getBlockElement(b, idx, 'footer')
          )}
        </Stack>
        <Stack flexDirection={'row'} gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
          {footer?.navitems?.map((item: INavItem, idx: number) => (
            <Button key={`${item.text}-${idx}`} component={Link} to={item.to} variant={'text'}>
              {item.text}
            </Button>
          ))}
        </Stack>
        <Typography variant={'body2'} fontSize={'1rem'} textAlign={'center'}>
          <Link to={'https://gk-lab.de'} target={'_blank'}>
            {footer?.copyright}
          </Link>
        </Typography>
      </Stack>
    </footer>
  )
}

import React from 'react'
import { Card, CardActions, CardContent, CardMedia, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { BlockCard } from '@gk-lab/kirbyreact/dist/esm/types/Block'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { getBlockElement } from '../../utils/sectionUtils'
import { BlockButton } from '../../../.yalc/@gk-lab/kirbyreact'

type Props = {
  sx?: SxProps<Theme>
  data: BlockCard
}

export default function CustomCard(props: Props): React.JSX.Element {
  const { sx, data } = props
  return (
    <Card sx={{ display: 'flex', width: '100%' }} variant={'outlined'}>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        {data.image && (
          <CardMedia
            sx={{
              minHeight: { xs: '280px', sm: '320px', md: '100%' },
              minWidth: 240
            }}
            image={data.image?.url}
            title={data.image?.name}
          />
        )}
        <Box sx={{ width: data.image ? 'unset' : '100%' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'inherit' }}>
              {data.heading}
            </Typography>
            {data.subtitle && (
              <Typography gutterBottom variant="h4" component="div" sx={{ textAlign: 'inherit' }}>
                {data.subtitle}
              </Typography>
            )}
            {data.copy && (
              <Typography
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: data.copy }}
                sx={{ textAlign: 'inherit' }}
              />
            )}
          </CardContent>
          {data.cta && <CardActions>{getBlockElement(data.cta, 0, 'card-action')}</CardActions>}
        </Box>
      </Stack>
    </Card>
  )
}

import {
  Block,
  BlockType,
  getKirbyBlockElement,
  IKirbySection,
  KirbySection,
  KirbyButton,
  KirbyBlockGroup,
  JSONViewer
} from '@gk-lab/kirbyreact'
import { Container, Grid, Stack } from '@mui/material'
import React from 'react'
import { CustomBlock, CustomBlockType } from '../types/Block'
import CustomCard from '../components/CustomCard/CustomCard'
import CustomMasonary from '../components/CustomMasonary/CustomMasonary'
import CustomImageList from '../components/CustomImageList/CustomImageList'

export type CustomSection = IKirbySection

/**
 * Get an route-template by section.id
 * @param section
 * @param parentUrl
 * @param inline
 */
export function getSectionElement(section: CustomSection, parentUrl: string) {
  switch (section.id) {
    case 'default':
    default:
      return (
        <KirbySection
          key={section.url}
          data={section}
          inline={false}
          parentUrl={parentUrl}
          getBlockElement={getBlockElement}
          // getFooter={() => <IDMFooter getBlockElement={getBlockElement} />}
          wrapWithRow={wrapWithRow}
        />
      )
  }
}

export function getBlockElement(
  b: Block | CustomBlock,
  idx: number,
  scope?: string
): React.JSX.Element | null {
  if (b) {
    switch (b.type) {
      case CustomBlockType.CUSTOM_COMPONENT:
        return <div id={'custom component'} key={`custom component-${idx.toString()}`} />
      case CustomBlockType.MASONARY:
        return (
          <CustomMasonary
            id={'custom masonary'}
            key={`custom masonary-${idx.toString()}`}
            itemData={b?.images}
          />
        )
      case CustomBlockType.IMAGELIST:
        return (
          <CustomImageList
            id={'custom imagelist'}
            key={`custom imagelist-${idx.toString()}`}
            itemData={b?.images}
          />
        )
      case BlockType.BLOCKGROUP:
        const elements = b.items?.map((b: Block) => getBlockElement(b, 0, scope))
        return <KirbyBlockGroup key={`blockgroup-${idx.toString()}`} data={b} elements={elements} />
      case BlockType.CARD:
        return <CustomCard key={`custom-card-${idx.toString()}`} data={b} />
      default:
        return getKirbyBlockElement(b, idx, scope)
    }
  }
  return null
}

export function wrapWithRow(element: React.JSX.Element, type?: BlockType | '', idx?: number) {
  const key = idx || type ? `${type ?? 'block'}-${idx ? idx.toString() : '0'}` : ''

  switch (type) {
    case BlockType.PAGECONTAINER:
      return (
        <Container maxWidth={'xl'} key={key}>
          <Grid container>
            <Grid item xs={12}>
              {element}
            </Grid>
          </Grid>
        </Container>
      )
    default:
      return (
        <Container maxWidth={'xl'} key={key}>
          <Grid container rowSpacing={4} justifyContent={'center'}>
            <Grid item xs={0} lg={2} xl={2} />
            <Grid item xs={12} lg={8} xl={8}>
              {element}
            </Grid>
            <Grid item xs={0} lg={2} xl={2} />
          </Grid>
        </Container>
      )
  }
}

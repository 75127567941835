import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Stack } from '@mui/material'
import { BlockButton, BlockLink, KirbyProps } from '@gk-lab/kirbyreact'
import { Link, useLocation } from 'react-router-dom'

interface Props extends KirbyProps {
  profile?: { url: string; alt: string }
  items?: (BlockButton | BlockLink)[]
}

export default function CustomAppBar(props: Props): React.JSX.Element {
  const { pathname } = useLocation()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const { sx, profile, items } = props

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="static">
      <Container maxWidth="lg" id={props.id}>
        <Toolbar disableGutters>
          {/* DESKTOP START */}
          <Stack
            flexDirection={{ xs: 'column' }}
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              flexGrow: 1,
              mt: 4
            }}
          >
            <Stack
              flexDirection={'row'}
              sx={{
                alignItems: 'center',
                gap: 2
              }}
            >
              {profile && (
                <Box>
                  <img src={profile.url} alt={profile.alt} width={'64'} height={'64'} />
                </Box>
              )}
              <Typography
                variant="h1"
                noWrap
                component="a"
                href="/"
                sx={{
                  mx: 2,
                  height: '3rem',
                  display: { xs: 'none', md: 'flex' },
                  textDecoration: 'none',
                  alignSelf: 'center'
                }}
              >
                Angy Gerigk
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={2} sx={{ my: 4 }}>
              {items?.map((item, idx) => (
                <Button
                  key={`${item.text}-${idx}`}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={item.to ?? '#'}
                  variant={pathname.indexOf(`/${item?.to}`) === 0 ? 'outlined' : 'contained'}
                >
                  {item.text}
                </Button>
              ))}
            </Stack>
          </Stack>
          {/* DESKTOP END */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {items?.map((item, idx) => {
                const className =
                  pathname.indexOf(`/${item.to}`) === 0 ? 'navigation active' : 'navigation'
                return (
                  <MenuItem
                    key={`${item.text}-${idx}`}
                    onClick={handleCloseNavMenu}
                    className={className}
                    component={Link}
                    to={item?.to ?? '#'}
                    sx={{
                      backgroundColor:
                        pathname.indexOf(`/${item?.to}`) === 0 ? 'primary.main' : 'common.white',
                      color:
                        pathname.indexOf(`/${item?.to}`) === 0 ? 'common.white' : 'secondary.main',
                      pointerEvents: pathname.indexOf(`/${item?.to}`) === 0 ? 'none' : 'auto'
                    }}
                  >
                    <Typography textAlign="center" sx={{ color: 'inherit', fontSize: "2rem", fontWeight: "bold" }}>
                      {item.text}
                    </Typography>
                  </MenuItem>
                )
              })}
            </Menu>
          </Box>

          <Stack
            flexDirection={'row'}
            sx={{
              alignItems: 'center',
              justifyItems: 'center',
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1
            }}
          >
            {profile && (
              <Box sx={{ mr: 1 }}>
                <img src={profile.url} alt={profile.alt} width={'48'} height={'48'} />
              </Box>
            )}
            <Typography
              variant="h1"
              noWrap
              component="a"
              href="/"
              sx={{
                mx: 2,
                textDecoration: 'none',
                alignSelf: 'center'
              }}
            >
              Angy Gerigk
            </Typography>
          </Stack>

          <Stack sx={{ flexGrow: 0, mr: 1 }}>
            <Tooltip title="facebook.com">
              <IconButton
                component={Link}
                to={'https://www.facebook.com/angy.vocal'}
                target={'_blank'}
              >
                <img src={'fb.png'} alt={'vocal-angy auf facebook'} width={'32'} height={'32'} />
              </IconButton>
            </Tooltip>
            <Tooltip title="instagram.com">
              <IconButton
                component={Link}
                to={'https://www.instagram.com/vocalangy'}
                target={'_blank'}
              >
                <img
                  src={'insta.png'}
                  alt={'vocal-angy auf instagram'}
                  width={'32'}
                  height={'32'}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

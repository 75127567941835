import React from 'react'
import { useSection } from '@gk-lab/kirbyreact'
import { Box, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { getBlockElement } from '../utils/sectionUtils'
import Container from '@mui/material/Container'

export function PageRoot(props: {}): React.JSX.Element {
  const { pageUrl } = useParams()
  const section = useSection(pageUrl ?? '')
  console.log('PageRoot - section: ', pageUrl, section)

  return (
    <Box>
      {/*<Jsonviewer data={section} name={`PageRoot: ${pageUrl}`} />*/}
      <Container maxWidth={'sm'}>
        <Stack
          sx={{
            width: '100%',
            justifyContent: 'center',
            justifyItems: 'center',
            alignItems: 'center',
            py: 8,
            px: 4,
            gap: 4
          }}
        >
          {section?.blocks?.map((b, idx) => getBlockElement(b, idx))}
        </Stack>
      </Container>
    </Box>
  )
}
